import React from "react";
import banner from "../asset/images/images/WhatsApp Image 2022-02-07 at 21.15.58.jpeg";

export const Banner = () => {
  return (
    <div className="home--banner mb-2 mb-md-4">
      <img src={banner} alt="" className="hero w-100" />
    </div>
  );
};
