import { useEffect } from "react"

const Profile = () => {

    useEffect(() => {
        //if(user === undefined) history.push('/')
    }, [])

    return (
        <h1>Orders list</h1>
    )
}
export default Profile